/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Image, Button } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"The Location"}>
        <SiteHeader />

        <Column className="pb--60 pt--60" name={"k5qcqoc9iwo"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":480}}>
              
              <Title className="title-box" content={"<br>Our&nbsp; Wedding Venue"}>
              </Title>

              <Text className="text-box" content={"Our wedding venue is a beautiful boutique hotel called Hotel Cortijo Bravo, located in the mountains outside of Velez Malaga. It is only accessible by car - therefore transportation to/from the wedding will be organized from nearby Nerja."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--justify" style={{"maxWidth":480,"marginBottom":0,"paddingBottom":0}}>
              
              <Image className="pt--12" alt={""} src={"https://cdn.swbpg.com/t/32129/ecede4b60dff4acf9f30504025a94104_e=181x24x420x420_s=350x_.jpeg"} sizes={"100vw"} style={{"maxWidth":448}} srcSet={"https://cdn.swbpg.com/t/32129/ecede4b60dff4acf9f30504025a94104_e=181x24x420x420_s=350x_.jpeg 350w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"2m30kl45nvs"} style={{"marginTop":0,"paddingTop":0,"paddingBottom":0}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"An area for everyone"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":977}} content={"Beyond great weather, there is a lot to enjoy about Southern Spain. To give you some inspiration, the following sections shows you some of the great activities that are on offer.&nbsp;"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"68fklbx1dw4"} style={{"marginTop":0,"paddingTop":0,"paddingBottom":0}}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Title className="title-box" content={"For the explorer"}>
              </Title>

              <Text className="text-box" content={"Take the chance to explore an area filled with beautiful nature, villages with character and flourishing cities alike&nbsp;"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pt--60" name={"u51clf9l87f"} style={{"marginBottom":0,"paddingBottom":0}} lightbox={false}>
          
          <ColumnWrap className="column__flex --left el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/32129/8cf0680b982d41489201f876a3c295c8_ove=ff5000x8_.png"} sizes={"100vw"} style={{"maxWidth":655}} srcSet={""} position={null}>
              </Image>

              <Title className="title-box" content={"Andalucia"}>
              </Title>

              <Text className="text-box fs--12 pt--16" style={{"maxWidth":467}} content={"Andalusia is the southernmost autonomous community in Peninsular Spain. It is the most populous and the second-largest autonomous community in the country. It is officially recognised as a historical nationality and a national reality. There are eight provinces in Andalucia: (from east to west) Huelva, Sevilla, Cádiz, Córdoba, Granada, Málaga, Jaén, and Almería. Each province has a capital city of the same name. Málaga, Granada, and Almeria have a Mediterranean coastline - Costa del Sol, Costa Tropical and Costa Almeria. Cadiz province also has a stretch of Mediterranean coastline in the Campo de Gibraltar area."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="pt--10" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/32129/52952278f18c415da235df918275b923_s=660x_.jpg"} sizes={"100vw"} style={{"maxWidth":655,"marginBottom":50}} srcSet={"https://cdn.swbpg.com/t/32129/52952278f18c415da235df918275b923_s=350x_.jpg 350w, https://cdn.swbpg.com/t/32129/52952278f18c415da235df918275b923_s=660x_.jpg 660w, https://cdn.swbpg.com/t/32129/52952278f18c415da235df918275b923_s=860x_.jpg 860w"} position={null}>
              </Image>

              <Title className="title-box fs--62 pb--02 pt--20" content={"Malaga with surroundings"}>
              </Title>

              <Text className="text-box fs--12" style={{"maxWidth":467}} content={"Malaga is best known for its string of popular beach resorts along the Costa del Sol and, although these have their own attractions, the province has much more to offer. Malaga city is a vibrant provincial capital with a fascinating history, while the area of the Costa del Sol to the east of Malaga city, towards Granada, is less developed, and is known as La Axarquia.  The Guadalhorce valley area leads from Malaga city to the Northern Malaga province, whose main town is Antequera. In the west are the picturesque mountain ranges and villages of the Serrania de Ronda.<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="pt--25">
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/32129/776fdbf1f3e14fb1b55da13bcd7a3da8_e=88x66x702x526_s=660x_.jpg"} sizes={"100vw"} style={{"maxWidth":764}} srcSet={"https://cdn.swbpg.com/t/32129/776fdbf1f3e14fb1b55da13bcd7a3da8_e=88x66x702x526_s=350x_.jpg 350w, https://cdn.swbpg.com/t/32129/776fdbf1f3e14fb1b55da13bcd7a3da8_e=88x66x702x526_s=660x_.jpg 660w"} position={null}>
              </Image>

              <Title className="title-box" content={"Frigiliana"}>
              </Title>

              <Text className="text-box fs--12 pt--02" style={{"maxWidth":467}} content={"An amazing village very close to our hearts (this is where Henrik’s parents partly live). Located a 10 minute drive away from Nerja, Frigiliana offers a cozy, authentic Spanish village experience. Voted one of the most picturesque villages in Spain, “Frigi” is both a great place to stay for your holiday or at least worth a day trip. <br><br><span style=\"font-weight: bold;\">Frigiliana Restaurants &amp; Activities: </span><br><br><span style=\"font-weight: bold;\"><a href=\"https://thegardenfrigiliana.com/\">The Garden:</a>&nbsp;</span>The best restaurant in town with incredible views<br><span style=\"font-weight: bold;\"><a href=\"https://goo.gl/maps/TczZQ9Ud7zR8mqCj9\">Plaza 45:</a>&nbsp;</span>Good Tapas at the central square<br><span style=\"font-weight: bold;\"><a href=\"https://goo.gl/maps/TczZQ9Ud7zR8mqCj9\">El Casino:</a></span> Good Spanish Restaurant with a view&nbsp;<br><span style=\"font-weight: bold;\"><a href=\"https://goo.gl/maps/TczZQ9Ud7zR8mqCj9\">Bar Virtues:</a></span> \nBang in the middle of Frigi - good for people spotting!\n<span style=\"font-weight: bold;\"><a href=\"https://goo.gl/maps/TczZQ9Ud7zR8mqCj9\">Hike to Acebuchal:</a></span>\nA beautiful hike to a tiny village with an incredible restaurant<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--10 pl--25 pr--25 pt--10" name={"prazdna_sekcee"} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--1 --full mt--10 pb--10" columns={"1"} fullscreen={true}>
          </ColumnWrap>

        </Column>


        <Column name={"68fklbx1dw4"} style={{"marginTop":0,"paddingTop":0,"paddingBottom":0}}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Title className="title-box" content={"For the active ones"}>
              </Title>

              <Text className="text-box" content={"For those who have unlimited amounts of energy, the region has so much to offer!"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"1xkt4ab4sat"} layout={"l4"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image className="--shape5" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/32129/084c512ccd2f4de4a4699baa12f288f2_e=28x18x165x165_.jpeg"} sizes={"100vw"} style={{"maxWidth":260}} srcSet={""}>
              </Image>

              <Text className="text-box" content={"Amazing views, beautiful weather and wonderful nature. You will fall in love with Frigiliana's hiking trails. <br><br>"}>
              </Text>

              <Button className="btn-box" url={"https://www.alltrails.com/spain/malaga/frigiliana"} href={"https://www.alltrails.com/spain/malaga/frigiliana"} content={"Hiking"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape5" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/32129/af64713dd1ff4480a96b048c1746a303_e=60x40x359x359_s=350x_.jpeg"} sizes={"100vw"} style={{"maxWidth":260}} srcSet={"https://cdn.swbpg.com/t/32129/af64713dd1ff4480a96b048c1746a303_e=60x40x359x359_s=350x_.jpeg 350w"}>
              </Image>

              <Text className="text-box" content={"It is not a secret that Andalucia is a golfers Mekka!&nbsp;&nbsp;<br><br><br>"}>
              </Text>

              <Button className="btn-box" url={"https://www.1golf.eu/en/golf-courses/spain/cities/nerja/"} href={"https://www.1golf.eu/en/golf-courses/spain/cities/nerja/"} content={"Golf"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape5" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/32129/83a3dabb00bd4d99af26eb2e78bc77b0_e=156x0x405x405_s=350x_.gif"} sizes={"100vw"} style={{"maxWidth":260}} srcSet={"https://cdn.swbpg.com/t/32129/83a3dabb00bd4d99af26eb2e78bc77b0_e=156x0x405x405_s=350x_.gif 350w"}>
              </Image>

              <Text className="text-box" content={"Padel is the inofficial National Sport of Spain (and Sweden...). If you fancy breaking a sweat - there are plenty of Padel courses in the area.&nbsp;"}>
              </Text>

              <Button className="btn-box" url={"https://nerja-holidays.com/padel/"} href={"https://nerja-holidays.com/padel/"} content={"Padel"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"68fklbx1dw4"} style={{"marginTop":0,"paddingTop":0,"paddingBottom":0}}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":800}}>
            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}